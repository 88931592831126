import "core-js/modules/es.array.splice.js";
import { get_ship_list, post_ship_list } from "../../api/index";
export default {
  name: "basetable",
  data: function data() {
    return {
      hoteldatares: {
        id: "",
        keyword: this.keyword,
        // 搜索
        page_index: 1,
        page_size: 5
      },
      addform: {
        type: "add",
        ship_name: this.ship_name,
        title: this.title,
        desc: this.desc,
        price: this.price,
        image_url: [],
        start_time: this.start_time,
        end_time: this.end_time
      },
      editform: {
        type: "edit",
        ship_name: this.ship_name,
        title: this.title,
        desc: this.desc,
        price: this.price,
        image_url: [],
        start_time: this.start_time,
        end_time: this.end_time
      },
      query: {
        address: "",
        name: ""
      },
      rules: {
        ship_name: [{
          required: true,
          message: "请输入游轮名称",
          trigger: "blur"
        }],
        title: [{
          required: true,
          message: "请输入旅游",
          trigger: "blur"
        }],
        desc: [{
          required: true,
          message: "请输入品牌描述",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请输入价格",
          trigger: "blur"
        }]
      },
      tableData: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      permissionBeans: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData: function getData() {
      var _this = this;

      get_ship_list(this.hoteldatares).then(function (res) {
        _this.tableData = res.data.list;
        _this.pageTotal = res.data.count;
      });
    },
    //创建时间格式化
    timestampToTime: function timestampToTime(row) {
      if (row.start_time == 0) {
        return "-";
      } else {
        var date = new Date(row.start_time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1) + ' ';
        var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1) + ':';
        var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes() + 1) : date.getMinutes() + 1) + ':';
        var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds() + 1) + ' ';
        return Y + M + D;
      }
    },
    timestampToTime2: function timestampToTime2(row) {
      if (row.end_time == 0) {
        return "-";
      } else {
        var date = new Date(row.end_time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1) + ' ';
        var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1) + ':';
        var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes() + 1) : date.getMinutes() + 1) + ':';
        var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds() + 1) + ' ';
        return Y + M + D;
      }
    },
    // 删除操作
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(function () {
        console.log(row);
        var data = {
          id: row.id,
          type: "delete"
        };
        post_ship_list(data).then(function (res) {
          if (res.code == 1) {
            console.log(res);

            _this2.$message.success("删除成功");

            _this2.tableData.splice(index, 1);

            _this2.getData();
          } else {
            _this2.$message.error(res.msg);
          }
        });
      }).catch(function () {});
    },
    addmenu: function addmenu() {
      this.addVisible = true;
    },
    // 添加保存
    addmenuFun: function addmenuFun(addform) {
      var _this3 = this;

      console.log(this.addform);
      this.$refs[addform].validate(function (valid) {
        if (valid) {
          post_ship_list(_this3.addform).then(function (res) {
            if (res.code == 1) {
              _this3.addVisible = false;

              _this3.$message.success(res.msg);

              _this3.getData();
            } else {
              console.log(res);

              _this3.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit: function handleEdit(index, row) {
      this.idx = index;
      this.editform.id = row.id;
      this.editform.ship_name = row.ship_name;
      this.editform.title = row.title;
      this.editform.desc = row.desc;
      this.editform.price = row.price;
      this.editform.image_url = row.image_url;
      this.editform.start_time = row.start_time;
      this.editform.end_time = row.end_time;
      this.editform.image_url = row.image_url;
      this.editVisible = true;
    },
    //上传图片
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.loading = false;
      this.addform.image_url.push(res.data.filepath);

      if (res.code == 1) {
        this.$message.success(res.msg);
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this4 = this;

      this.loading = true;
      this.progressData = 0;
      var timer2 = setInterval(function () {
        _this4.progressData = _this4.progressData + 1;

        if (_this4.progressData >= 100) {
          clearInterval(timer2);
        }
      }, 200);
      var isJPG = file.type === 'image/jpeg/jpg';
      var isLt5M = file.size / 1024 / 1024 < 10; // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
      // }
      // if (!isLt5M) {
      //   this.$message.error("上传头像图片大小不能超过 10MB!");
      // }

      return isLt5M;
    },
    // 保存编辑
    saveEdit: function saveEdit() {
      var _this5 = this;

      post_ship_list(this.editform).then(function (res) {
        if (res.code == 1) {
          _this5.editVisible = false;

          _this5.$message.success(res.msg);

          _this5.getData();
        } else {
          _this5.$message.error(res.msg);
        }
      });
    },
    // 分页导航
    handleCurrentChange: function handleCurrentChange(val) {
      this.hoteldatares.page_index = val;
      console.log(this.hoteldatares.page_index); //点击第几页

      this.getData();
    }
  }
};